import { useState, useRef } from "react";
import QRCode from "react-qr-code";
import html2canvas from "html2canvas";
import "./App.css";

function App() {
  const [value, setValue] = useState("");
  const qrRef = useRef(null);

  const downloadQRCode = async () => {
    if (qrRef.current) {
      const canvas = await html2canvas(qrRef.current);
      const imgData = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = imgData;
      link.download = "qrcode.png";
      link.click();
    }
  };

  return (
    <div className="App">
      <div style={{ textAlign: "center", marginTop: 50 }}>
        <input
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="Введите текст"
        />
        <br /> <br />
        <div ref={qrRef} style={{ display: "inline-block", padding: 10, background: "#fff" }}>
          <QRCode size={256} value={value || ""} />
        </div>
        <br /> <br />
        <button onClick={downloadQRCode}>Скачать QR-код</button>
      </div>
    </div>
  );
}

export default App;
